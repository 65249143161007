
@tailwind base;

@tailwind components;

@tailwind utilities;

// 各ページの根本の div であてられている `cursor: pointer;` を打ち消している
main div {
  cursor: auto;
}

// anchor とその子孫全てに指定しないと anchor 部分に hover してもカーソルが pointer にならない
a, a * {
  cursor: pointer;
}

.hover-underline:hover {
  text-decoration: underline;
}

#nav {
		position: absolute;
		width: 1536px;
		height: 100px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Rectangle_24403 {
		fill: transparent;
	}
	.Rectangle_24403 {
		position: absolute;
		overflow: visible;
		width: 1536px;
		height: 100px;
		left: 0px;
		top: 0px;
	}
	#menu_link {
		position: absolute;
		width: 86px;
		height: 16px;
		left: 851px;
		top: 44px;
		overflow: visible;
	}
	#Text_it {
		left: 0px;
		top: -3px;
		position: absolute;
		overflow: visible;
		width: 87px;
		white-space: nowrap;
		text-align: center;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(22,24,26,1);
		letter-spacing: 0.8px;
	}
	#Mask_Group_2 {
		position: absolute;
		width: 265px;
		height: 36px;
		left: 36px;
		top: 30px;
		overflow: visible;
	}
	#Group_30072 {
		position: absolute;
		width: 131.465px;
		height: 27.355px;
		left: 1368.535px;
		top: 40px;
		overflow: visible;
	}
	#menu_link_iw {
		position: absolute;
		width: 17.465px;
		height: 27.355px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#JP {
		// left: 0.465px;
		// top: 0px;
		// position: absolute;
		overflow: visible;
		width: 18px;
		white-space: nowrap;
		text-align: center;
		font-family: EB Garamond;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,127,211,1);
		letter-spacing: 0.8px;
	}
	#Path_278536 {
		fill: transparent;
		stroke: rgba(0,127,211,1);
		stroke-width: 1.5px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Path_278536 {
		overflow: visible;
		position: absolute;
		width: 18px;
		height: 1px;
		transform: translate(0px, 0px) matrix(1,0,0,1,-0.2675,25.1781) rotate(166deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#Text_iz {
		// left: 25.465px;
		top: 1px;
		position: absolute;
		overflow: visible;
		width: 17px;
		white-space: nowrap;
		text-align: left;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(22,24,26,1);
	}
	#Text_i {
		left: 81.465px;
		top: 1px;
		position: absolute;
		overflow: visible;
		width: 17px;
		white-space: nowrap;
		text-align: left;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(22,24,26,1);
	}
	#menu_link_i {
		position: absolute;
		width: 24px;
		height: 21px;
		left: 49.465px;
		top: 0px;
		overflow: visible;
	}
	#EN {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 25px;
		white-space: nowrap;
		text-align: center;
		font-family: EB Garamond;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(22,24,26,1);
		letter-spacing: 0.8px;
	}
	#menu_link_ja {
		position: absolute;
		width: 26px;
		height: 21px;
		left: 105.465px;
		top: 0px;
		overflow: visible;
	}
	#CN {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 27px;
		white-space: nowrap;
		text-align: center;
		font-family: EB Garamond;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(22,24,26,1);
		letter-spacing: 0.8px;
	}
	#menu_link_jb {
		position: absolute;
		width: 102px;
		height: 16px;
		left: 977px;
		top: 44px;
		overflow: visible;
	}
	#Text_ja {
		left: 0px;
		top: -3px;
		position: absolute;
		overflow: visible;
		width: 103px;
		white-space: nowrap;
		text-align: center;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(22,24,26,1);
		letter-spacing: 0.8px;
	}
	#menu_link_jc {
		position: absolute;
		width: 102px;
		height: 16px;
		left: 1119px;
		top: 44px;
		overflow: visible;
	}
	#Text_jb {
		left: 0px;
		top: -3px;
		position: absolute;
		overflow: visible;
		width: 103px;
		white-space: nowrap;
		text-align: center;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(22,24,26,1);
		letter-spacing: 0.8px;
	}
	#menu_link_jd {
		position: absolute;
		width: 68px;
		height: 16px;
		left: 1261px;
		top: 44px;
		overflow: visible;
	}
	#Text_jc {
		left: 0px;
		top: -3px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		text-align: center;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(22,24,26,1);
		letter-spacing: 0.8px;
	}

  .Footer_cardWrap {
    flex-direction: row;
  }

  @media screen and (max-width: 768px) {
    .Footer_cardWrap {
      flex-direction: column;
    }
  }

  // Footer
  #common_footer {
		width: 100%;
		height: 630px;
		left: 0px;
		overflow: visible;
    position: relative;
	}
	#Path_277689 {
		fill: rgba(0,44,93,1);
	}
	.Path_277689 {
		overflow: visible;
		width: 100%;
		position: absolute;
		height: 630px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Group_29869 {
		position: absolute;
		width: 144px;
		height: 142px;
		left: 128px;
		top: 82px;
		overflow: visible;
	}
	#Text_je {
		left: 0px;
		top: 40px;
		position: absolute;
		overflow: visible;
		width: 81px;
		white-space: nowrap;
		text-align: left;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#Text_jf {
		left: 0px;
		top: 120px;
		position: absolute;
		overflow: visible;
		width: 65px;
		white-space: nowrap;
		text-align: left;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#Text_jg {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 97px;
		white-space: nowrap;
		text-align: left;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#Text_jh {
		left: 0px;
		top: 80px;
		position: absolute;
		overflow: visible;
		width: 145px;
		white-space: nowrap;
		text-align: left;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#Line_127 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_127 {
		overflow: visible;
		position: absolute;
		width: 1280px;
		height: 1px;
		left: 128px;
		top: 380.998px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Group_29921 {
		position: absolute;
		width: 1063px;
		height: 59px;
		left: 128px;
		top: 441px;
		overflow: visible;
	}
	#Group_29882 {
		position: absolute;
		width: 247px;
		height: 46px;
		left: 0px;
		top: 1px;
		overflow: visible;
	}
	#Group_29822 {
		position: absolute;
		width: 246.061px;
		height: 34.841px;
		left: 0.369px;
		top: 5.205px;
		overflow: visible;
	}
	#Group_30055 {
		position: absolute;
		width: 246.061px;
		height: 34.841px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Path_277562 {
		fill: rgba(255,255,255,1);
	}
	.Path_277562 {
		overflow: visible;
		position: absolute;
		width: 4.063px;
		height: 5.507px;
		left: 56.664px;
		top: 29.255px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277563 {
		fill: rgba(255,255,255,1);
	}
	.Path_277563 {
		overflow: visible;
		position: absolute;
		width: 5.193px;
		height: 5.585px;
		left: 61.695px;
		top: 29.176px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277564 {
		fill: rgba(255,255,255,1);
	}
	.Path_277564 {
		overflow: visible;
		position: absolute;
		width: 4.57px;
		height: 5.664px;
		left: 68.445px;
		top: 29.176px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277565 {
		fill: rgba(255,255,255,1);
	}
	.Path_277565 {
		overflow: visible;
		position: absolute;
		width: 5.191px;
		height: 5.585px;
		left: 74.57px;
		top: 29.176px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277566 {
		fill: rgba(255,255,255,1);
	}
	.Path_277566 {
		overflow: visible;
		position: absolute;
		width: 4.711px;
		height: 5.507px;
		left: 81.318px;
		top: 29.255px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277567 {
		fill: rgba(255,255,255,1);
	}
	.Path_277567 {
		overflow: visible;
		position: absolute;
		width: 3.445px;
		height: 5.507px;
		left: 87.947px;
		top: 29.255px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277568 {
		fill: rgba(255,255,255,1);
	}
	.Path_277568 {
		overflow: visible;
		position: absolute;
		width: 5.193px;
		height: 5.585px;
		left: 95.127px;
		top: 29.176px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277569 {
		fill: rgba(255,255,255,1);
	}
	.Path_277569 {
		overflow: visible;
		position: absolute;
		width: 4.799px;
		height: 5.664px;
		left: 101.443px;
		top: 29.176px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277570 {
		fill: rgba(255,255,255,1);
	}
	.Path_277570 {
		overflow: visible;
		position: absolute;
		width: 4.801px;
		height: 5.664px;
		left: 107.68px;
		top: 29.176px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277571 {
		fill: rgba(255,255,255,1);
	}
	.Path_277571 {
		overflow: visible;
		position: absolute;
		width: 5.656px;
		height: 5.664px;
		left: 113.918px;
		top: 29.176px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277572 {
		fill: rgba(255,255,255,1);
	}
	.Path_277572 {
		overflow: visible;
		position: absolute;
		width: 4.281px;
		height: 5.586px;
		left: 121.41px;
		top: 29.255px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277573 {
		fill: rgba(255,255,255,1);
	}
	.Path_277573 {
		overflow: visible;
		position: absolute;
		width: 4.57px;
		height: 5.664px;
		left: 127.932px;
		top: 29.176px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277574 {
		fill: rgba(255,255,255,1);
	}
	.Path_277574 {
		overflow: visible;
		position: absolute;
		width: 3.682px;
		height: 5.507px;
		left: 134.135px;
		top: 29.255px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Rectangle_24429 {
		fill: rgba(255,255,255,1);
	}
	.Rectangle_24429 {
		position: absolute;
		overflow: visible;
		width: 0.818px;
		height: 5.507px;
		left: 139.451px;
		top: 29.255px;
	}
	#Path_277575 {
		fill: rgba(255,255,255,1);
	}
	.Path_277575 {
		overflow: visible;
		position: absolute;
		width: 4.568px;
		height: 5.664px;
		left: 142.582px;
		top: 29.176px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277576 {
		fill: rgba(255,255,255,1);
	}
	.Path_277576 {
		overflow: visible;
		position: absolute;
		width: 4.846px;
		height: 5.664px;
		left: 149.063px;
		top: 29.176px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277577 {
		fill: rgba(255,255,255,1);
	}
	.Path_277577 {
		overflow: visible;
		position: absolute;
		width: 5.656px;
		height: 5.664px;
		left: 158.043px;
		top: 29.176px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277578 {
		fill: rgba(255,255,255,1);
	}
	.Path_277578 {
		overflow: visible;
		position: absolute;
		width: 3.445px;
		height: 5.507px;
		left: 165.609px;
		top: 29.255px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277579 {
		fill: rgba(255,255,255,1);
	}
	.Path_277579 {
		overflow: visible;
		position: absolute;
		width: 3.443px;
		height: 5.507px;
		left: 170.848px;
		top: 29.255px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Rectangle_24430 {
		fill: rgba(255,255,255,1);
	}
	.Rectangle_24430 {
		position: absolute;
		overflow: visible;
		width: 0.818px;
		height: 5.507px;
		left: 176.084px;
		top: 29.255px;
	}
	#Path_277580 {
		fill: rgba(255,255,255,1);
	}
	.Path_277580 {
		overflow: visible;
		position: absolute;
		width: 4.801px;
		height: 5.664px;
		left: 178.814px;
		top: 29.176px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277581 {
		fill: rgba(255,255,255,1);
	}
	.Path_277581 {
		overflow: visible;
		position: absolute;
		width: 3.445px;
		height: 5.507px;
		left: 185.473px;
		top: 29.255px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277583 {
		fill: rgba(255,255,255,1);
	}
	.Path_277583 {
		overflow: visible;
		position: absolute;
		width: 10.475px;
		height: 17.112px;
		left: 8.314px;
		top: 0.182px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277584 {
		fill: rgba(255,255,255,1);
	}
	.Path_277584 {
		overflow: visible;
		position: absolute;
		width: 10.553px;
		height: 17.191px;
		left: 8.273px;
		top: 0.143px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277585 {
		fill: rgba(255,255,255,1);
	}
	.Path_277585 {
		overflow: visible;
		position: absolute;
		width: 7.951px;
		height: 17.701px;
		left: 0.039px;
		top: 0.202px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277586 {
		fill: rgba(255,255,255,1);
	}
	.Path_277586 {
		overflow: visible;
		position: absolute;
		width: 8.029px;
		height: 17.778px;
		left: 0px;
		top: 0.163px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277587 {
		fill: rgba(255,255,255,1);
	}
	.Path_277587 {
		overflow: visible;
		position: absolute;
		width: 2.639px;
		height: 3.077px;
		left: 9.203px;
		top: 0.448px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277588 {
		fill: rgba(255,255,255,1);
	}
	.Path_277588 {
		overflow: visible;
		position: absolute;
		width: 2.719px;
		height: 3.156px;
		left: 9.162px;
		top: 0.408px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277589 {
		fill: rgba(255,255,255,1);
	}
	.Path_277589 {
		overflow: visible;
		position: absolute;
		width: 6.586px;
		height: 6.993px;
		left: 5.563px;
		top: 10.868px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277590 {
		fill: rgba(255,255,255,1);
	}
	.Path_277590 {
		overflow: visible;
		position: absolute;
		width: 6.666px;
		height: 7.073px;
		left: 5.521px;
		top: 10.828px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277591 {
		fill: rgba(255,255,255,1);
	}
	.Path_277591 {
		overflow: visible;
		position: absolute;
		width: 2.373px;
		height: 3.045px;
		left: 5.277px;
		top: 8.055px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277592 {
		fill: rgba(255,255,255,1);
	}
	.Path_277592 {
		overflow: visible;
		position: absolute;
		width: 2.453px;
		height: 3.124px;
		left: 5.236px;
		top: 8.016px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277593 {
		fill: rgba(255,255,255,1);
	}
	.Path_277593 {
		overflow: visible;
		position: absolute;
		width: 7.207px;
		height: 13.514px;
		left: 22.902px;
		top: 1.367px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277594 {
		fill: rgba(255,255,255,1);
	}
	.Path_277594 {
		overflow: visible;
		position: absolute;
		width: 7.285px;
		height: 13.594px;
		left: 22.863px;
		top: 1.327px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277595 {
		fill: rgba(255,255,255,1);
	}
	.Path_277595 {
		overflow: visible;
		position: absolute;
		width: 13.316px;
		height: 16.157px;
		left: 27.793px;
		top: 0.774px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277596 {
		fill: rgba(255,255,255,1);
	}
	.Path_277596 {
		overflow: visible;
		position: absolute;
		width: 13.396px;
		height: 16.237px;
		left: 27.752px;
		top: 0.733px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277597 {
		fill: rgba(255,255,255,1);
	}
	.Path_277597 {
		overflow: visible;
		position: absolute;
		width: 17.557px;
		height: 16.36px;
		left: 44.955px;
		top: 0.445px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277598 {
		fill: rgba(255,255,255,1);
	}
	.Path_277598 {
		overflow: visible;
		position: absolute;
		width: 17.637px;
		height: 16.441px;
		left: 44.914px;
		top: 0.405px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277599 {
		fill: rgba(255,255,255,1);
	}
	.Path_277599 {
		overflow: visible;
		position: absolute;
		width: 17.842px;
		height: 17.151px;
		left: 66.592px;
		top: 0.345px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277600 {
		fill: rgba(255,255,255,1);
	}
	.Path_277600 {
		overflow: visible;
		position: absolute;
		width: 17.922px;
		height: 17.231px;
		left: 66.553px;
		top: 0.304px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277601 {
		fill: rgba(255,255,255,1);
	}
	.Path_277601 {
		overflow: visible;
		position: absolute;
		width: 18.148px;
		height: 16.929px;
		left: 86.951px;
		top: 0.71px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277602 {
		fill: rgba(255,255,255,1);
	}
	.Path_277602 {
		overflow: visible;
		position: absolute;
		width: 18.225px;
		height: 17.009px;
		left: 86.914px;
		top: 0.67px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277603 {
		fill: rgba(255,255,255,1);
	}
	.Path_277603 {
		overflow: visible;
		position: absolute;
		width: 6.314px;
		height: 4.36px;
		left: 125.313px;
		top: 13.914px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277604 {
		fill: rgba(255,255,255,1);
	}
	.Path_277604 {
		overflow: visible;
		position: absolute;
		width: 6.395px;
		height: 4.438px;
		left: 125.271px;
		top: 13.876px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277605 {
		fill: rgba(255,255,255,1);
	}
	.Path_277605 {
		overflow: visible;
		position: absolute;
		width: 18.695px;
		height: 17.829px;
		left: 115.998px;
		top: 0.039px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277606 {
		fill: rgba(255,255,255,1);
	}
	.Path_277606 {
		overflow: visible;
		position: absolute;
		width: 18.775px;
		height: 17.907px;
		left: 115.957px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277607 {
		fill: rgba(255,255,255,1);
	}
	.Path_277607 {
		overflow: visible;
		position: absolute;
		width: 9.682px;
		height: 9.687px;
		left: 138.672px;
		top: 0.039px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277608 {
		fill: rgba(255,255,255,1);
	}
	.Path_277608 {
		overflow: visible;
		position: absolute;
		width: 9.76px;
		height: 9.767px;
		left: 138.633px;
		top: -0.001px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277609 {
		fill: rgba(255,255,255,1);
	}
	.Path_277609 {
		overflow: visible;
		position: absolute;
		width: 8.303px;
		height: 1.352px;
		left: 143.301px;
		top: 6.828px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277610 {
		fill: rgba(255,255,255,1);
	}
	.Path_277610 {
		overflow: visible;
		position: absolute;
		width: 8.383px;
		height: 1.431px;
		left: 143.26px;
		top: 6.788px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277611 {
		fill: rgba(255,255,255,1);
	}
	.Path_277611 {
		overflow: visible;
		position: absolute;
		width: 15.711px;
		height: 7.747px;
		left: 139.891px;
		top: 9.87px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277612 {
		fill: rgba(255,255,255,1);
	}
	.Path_277612 {
		overflow: visible;
		position: absolute;
		width: 15.791px;
		height: 7.826px;
		left: 139.848px;
		top: 9.831px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277613 {
		fill: rgba(255,255,255,1);
	}
	.Path_277613 {
		overflow: visible;
		position: absolute;
		width: 9.254px;
		height: 7.393px;
		left: 147.951px;
		top: 1.721px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277614 {
		fill: rgba(255,255,255,1);
	}
	.Path_277614 {
		overflow: visible;
		position: absolute;
		width: 9.334px;
		height: 7.473px;
		left: 147.908px;
		top: 1.681px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277615 {
		fill: rgba(255,255,255,1);
	}
	.Path_277615 {
		overflow: visible;
		position: absolute;
		width: 6.182px;
		height: 1.542px;
		left: 162.229px;
		top: 8.22px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277616 {
		fill: rgba(255,255,255,1);
	}
	.Path_277616 {
		overflow: visible;
		position: absolute;
		width: 6.262px;
		height: 1.622px;
		left: 162.186px;
		top: 8.18px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277617 {
		fill: rgba(255,255,255,1);
	}
	.Path_277617 {
		overflow: visible;
		position: absolute;
		width: 6.426px;
		height: 1.604px;
		left: 162.125px;
		top: 0.485px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277618 {
		fill: rgba(255,255,255,1);
	}
	.Path_277618 {
		overflow: visible;
		position: absolute;
		width: 6.504px;
		height: 1.683px;
		left: 162.086px;
		top: 0.446px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277619 {
		fill: rgba(255,255,255,1);
	}
	.Path_277619 {
		overflow: visible;
		position: absolute;
		width: 8.721px;
		height: 1.604px;
		left: 161.068px;
		top: 3.043px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277620 {
		fill: rgba(255,255,255,1);
	}
	.Path_277620 {
		overflow: visible;
		position: absolute;
		width: 8.799px;
		height: 1.683px;
		left: 161.031px;
		top: 3.004px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277621 {
		fill: rgba(255,255,255,1);
	}
	.Path_277621 {
		overflow: visible;
		position: absolute;
		width: 10.23px;
		height: 17.64px;
		left: 169.066px;
		top: 0.161px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277622 {
		fill: rgba(255,255,255,1);
	}
	.Path_277622 {
		overflow: visible;
		position: absolute;
		width: 10.309px;
		height: 17.719px;
		left: 169.025px;
		top: 0.121px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277623 {
		fill: rgba(255,255,255,1);
	}
	.Path_277623 {
		overflow: visible;
		position: absolute;
		width: 6.988px;
		height: 6.886px;
		left: 162.018px;
		top: 11.001px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277624 {
		fill: rgba(255,255,255,1);
	}
	.Path_277624 {
		overflow: visible;
		position: absolute;
		width: 7.068px;
		height: 6.965px;
		left: 161.975px;
		top: 10.961px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277625 {
		fill: rgba(255,255,255,1);
	}
	.Path_277625 {
		overflow: visible;
		position: absolute;
		width: 6.242px;
		height: 1.542px;
		left: 162.168px;
		top: 5.642px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277626 {
		fill: rgba(255,255,255,1);
	}
	.Path_277626 {
		overflow: visible;
		position: absolute;
		width: 6.322px;
		height: 1.623px;
		left: 162.125px;
		top: 5.601px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277627 {
		fill: rgba(255,255,255,1);
	}
	.Path_277627 {
		overflow: visible;
		position: absolute;
		width: 18.045px;
		height: 17.864px;
		left: 182.801px;
		top: 0.099px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277628 {
		fill: rgba(255,255,255,1);
	}
	.Path_277628 {
		overflow: visible;
		position: absolute;
		width: 18.127px;
		height: 17.943px;
		left: 182.76px;
		top: 0.06px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277629 {
		fill: rgba(255,255,255,1);
	}
	.Path_277629 {
		overflow: visible;
		position: absolute;
		width: 9.021px;
		height: 11.624px;
		left: 204.563px;
		top: 5.994px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277630 {
		fill: rgba(255,255,255,1);
	}
	.Path_277630 {
		overflow: visible;
		position: absolute;
		width: 9.1px;
		height: 11.702px;
		left: 204.521px;
		top: 5.955px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277631 {
		fill: rgba(255,255,255,1);
	}
	.Path_277631 {
		overflow: visible;
		position: absolute;
		width: 7.631px;
		height: 5.055px;
		left: 205.639px;
		top: 1.063px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277632 {
		fill: rgba(255,255,255,1);
	}
	.Path_277632 {
		overflow: visible;
		position: absolute;
		width: 7.711px;
		height: 5.134px;
		left: 205.598px;
		top: 1.025px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277633 {
		fill: rgba(255,255,255,1);
	}
	.Path_277633 {
		overflow: visible;
		position: absolute;
		width: 11.486px;
		height: 9.117px;
		left: 210.5px;
		top: 8.627px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277634 {
		fill: rgba(255,255,255,1);
	}
	.Path_277634 {
		overflow: visible;
		position: absolute;
		width: 11.566px;
		height: 9.197px;
		left: 210.459px;
		top: 8.586px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277635 {
		fill: rgba(255,255,255,1);
	}
	.Path_277635 {
		overflow: visible;
		position: absolute;
		width: 12.689px;
		height: 10.187px;
		left: 211.055px;
		top: 0.06px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277636 {
		fill: rgba(255,255,255,1);
	}
	.Path_277636 {
		overflow: visible;
		position: absolute;
		width: 12.77px;
		height: 10.267px;
		left: 211.014px;
		top: 0.021px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277637 {
		fill: rgba(255,255,255,1);
	}
	.Path_277637 {
		overflow: visible;
		position: absolute;
		width: 9.238px;
		height: 13.803px;
		left: 226.945px;
		top: 3.896px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277638 {
		fill: rgba(255,255,255,1);
	}
	.Path_277638 {
		overflow: visible;
		position: absolute;
		width: 9.318px;
		height: 13.884px;
		left: 226.904px;
		top: 3.856px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277639 {
		fill: rgba(255,255,255,1);
	}
	.Path_277639 {
		overflow: visible;
		position: absolute;
		width: 8.648px;
		height: 1.395px;
		left: 228.148px;
		top: 0.959px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277640 {
		fill: rgba(255,255,255,1);
	}
	.Path_277640 {
		overflow: visible;
		position: absolute;
		width: 8.727px;
		height: 1.473px;
		left: 228.109px;
		top: 0.92px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277641 {
		fill: rgba(255,255,255,1);
	}
	.Path_277641 {
		overflow: visible;
		position: absolute;
		width: 13.201px;
		height: 17.192px;
		left: 232.818px;
		top: 0.608px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277642 {
		fill: rgba(255,255,255,1);
	}
	.Path_277642 {
		overflow: visible;
		position: absolute;
		width: 13.279px;
		height: 17.272px;
		left: 232.779px;
		top: 0.567px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277643 {
		fill: rgba(255,255,255,1);
	}
	.Path_277643 {
		overflow: visible;
		position: absolute;
		width: 1.586px;
		height: 9.629px;
		left: 241.223px;
		top: 8.03px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_277644 {
		fill: rgba(255,255,255,1);
	}
	.Path_277644 {
		overflow: visible;
		position: absolute;
		width: 1.666px;
		height: 9.708px;
		left: 241.182px;
		top: 7.991px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Rectangle_24432 {
		fill: transparent;
	}
	.Rectangle_24432 {
		position: absolute;
		overflow: visible;
		width: 247px;
		height: 46px;
		left: 0px;
		top: 0px;
	}
	#n_ {
		left: 347px;
		top: 3px;
		position: absolute;
		overflow: visible;
		width: 129px;
		white-space: nowrap;
		text-align: left;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#n_20-0837_1-2_TOKAI7 {
		left: 347px;
		top: 38px;
		position: absolute;
		overflow: visible;
		width: 334px;
		white-space: nowrap;
		text-align: left;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(255,255,255,1);
	}
	#Group_29868 {
		position: absolute;
		width: 142px;
		height: 26px;
		left: 742px;
		top: 0px;
		overflow: visible;
	}
	#n_54-376-4245 {
		overflow: visible;
		width: 127px;
    margin-left: 4px;
		white-space: nowrap;
		text-align: left;
		font-family: Zen Old Mincho;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.4px;
	}
  .telNumber {
    margin-bottom: -1px;
  }
	#Path_16 {
		fill: rgba(255,255,255,1);
	}
	.Path_16 {
		overflow: visible;
		position: relative;
		width: 10px;
    top: 2px;
		transform: matrix(1,0,0,1,0,0);

    @media screen and (max-width: 768px) {
      width: 12px;
    }
	}
	#n_30-1730 {
		left: 742px;
		top: 38px;
		position: absolute;
		overflow: visible;
		width: 142px;
		white-space: nowrap;
		text-align: left;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(255,255,255,1);
	}
	#Group_30545 {
		position: absolute;
		width: 119px;
		height: 22px;
		left: 944px;
		top: 3px;
		overflow: visible;
	}
	#Text_l {
		left: 23px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 97px;
		white-space: nowrap;
		text-align: left;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#Group_30456 {
		position: absolute;
		width: 14px;
		height: 9.5px;
		left: 0px;
		top: 6.531px;
		overflow: visible;
	}
	#Rectangle_24659 {
		fill: rgba(0,0,0,0);
		stroke: rgba(255,255,255,1);
		stroke-width: 1px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Rectangle_24659 {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 10.5px;
		left: 0px;
		top: 0px;
	}
	#Group_30172 {
		position: absolute;
		width: 12.795px;
		height: 5.892px;
		left: 0.527px;
		top: 0.593px;
		overflow: visible;
	}
	#Path_278123 {
		fill: rgba(0,0,0,0);
		stroke: rgba(255,255,255,1);
		stroke-width: 1px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Path_278123 {
		overflow: visible;
		position: absolute;
		width: 14.209px;
		height: 7.14px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_278475 {
		fill: rgba(0,0,0,0);
		stroke: rgba(255,255,255,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Path_278475 {
		overflow: visible;
		position: absolute;
		width: 5.854px;
		height: 4.967px;
		left: 0.527px;
		top: 5.469px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_278476 {
		fill: rgba(0,0,0,0);
		stroke: rgba(255,255,255,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Path_278476 {
		overflow: visible;
		position: absolute;
		width: 5.854px;
		height: 4.967px;
		left: 8.875px;
		top: 5.469px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Group_30544 {
		position: absolute;
		width: 86px;
		height: 22px;
		left: 946px;
		top: 37px;
		overflow: visible;
	}
	#Text_mf {
		left: 22px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 65px;
		white-space: nowrap;
		text-align: left;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#Group_30457 {
		position: absolute;
		width: 12px;
		height: 15px;
		left: 0px;
		top: 2.5px;
		overflow: visible;
	}
	#Path_278129_mh {
		fill: rgba(0,0,0,0);
		stroke: rgba(255,255,255,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.Path_278129_mh {
		overflow: visible;
		position: absolute;
		width: 13px;
		height: 16px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_278130_mi {
		fill: rgba(0,0,0,0);
		stroke: rgba(255,255,255,1);
		stroke-width: 1px;
		stroke-linejoin: round;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Path_278130_mi {
		overflow: visible;
		position: absolute;
		width: 5.078px;
		height: 4.358px;
		left: 7.422px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Line_138_mj {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 1px;
		stroke-linejoin: round;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_138_mj {
		overflow: visible;
		position: absolute;
		width: 5.598px;
		height: 1px;
		left: 3.201px;
		top: 5.998px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Line_140_mk {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 1px;
		stroke-linejoin: round;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_140_mk {
		overflow: visible;
		position: absolute;
		width: 4.303px;
		height: 1.012px;
		left: 3.199px;
		top: 10.902px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Line_372 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 1px;
		stroke-linejoin: round;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_372 {
		overflow: visible;
		position: absolute;
		width: 5.598px;
		height: 1px;
		left: 3.201px;
		top: 8.456px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Group_29920 {
		position: absolute;
		width: 646px;
		height: 17px;
		left: 442px;
		top: 575px;
		overflow: visible;
	}
	#Text_mn {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 121px;
		white-space: nowrap;
		text-align: left;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(255,255,255,1);
	}
	#Text_mo {
		left: 159px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 265px;
		white-space: nowrap;
		text-align: left;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(255,255,255,1);
	}
	#n__All_rights_reserved {
		left: 463px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 184px;
		white-space: nowrap;
		text-align: left;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(255,255,255,1);
	}
	#Group_29870 {
		overflow: visible;
	}
	#n__ {
		width: 171px;
		white-space: nowrap;
		text-align: left;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#n___ms {
		white-space: nowrap;
		text-align: left;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#n___mt {
		white-space: nowrap;
		text-align: left;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#n___mu {
		overflow: visible;
		width: 251px;
		white-space: nowrap;
		text-align: left;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#n___mv {
		overflow: visible;
		width: 187px;
		white-space: nowrap;
		text-align: left;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#Text_mw {
		overflow: visible;
		white-space: nowrap;
		text-align: left;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#Group_29871 {
		position: absolute;
		width: 128px;
		height: 142px;
		left: 843px;
		top: 82px;
		overflow: visible;
	}

  .Header {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 8vw;
    gap: 32px;
    padding: 0 40px;
    transition: all .3s ease;
  }

  .HeaderSp {
    display: none;
  }

  .HeaderSp_overlay {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    z-index: 99999;
    top: 0;
    left: 0;
    opacity: 0;
    transition: 0.3s ease-in-out all;
    display: none;
  }

  .SpMenu {
    background-color: #007BC6;
    height: 100vh;
    width: 320px;
    position: fixed;
    right: -320px;
    top: 0;
    transition: 0.3s ease-in-out all;
    z-index: 100000;
  }

  .SpMenu.open {
    right: 0;
  }

  .Header_menu {
    display: flex;
    gap: 32px;
		white-space: nowrap;
		text-align: center;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(22,24,26,1);
		letter-spacing: 0.8px;

    div.active {
      color: rgba(0,127,211,1);
    }
    a {
      position: relative;
      &::after{
        opacity: 0;
        content: "";
        transition: all 0.3s ease;
        content: "";
        position: absolute;
        bottom: -8px;
        left: 50%;
        z-index: 10;
        display: block;
        width: 32px;
        height: 2px;
        background-color: rgba(0,127,211,1);
        transform: translateX(-50%) rotate(-15deg);
      }
    }
    a:hover {
      color: rgba(0,127,211,1);
      &::after {
        opacity: 1;
      }
    }
  }

  .Header_lang {
    font-family: EB Garamond !important;
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
    padding-left: 40px;

    button.active {
      color: rgba(0,127,211,1);
    }
  }


.Header_lang-acitve {
    position: absolute;
    bottom: 0px;
    left: 50%;
    z-index: 10;
    display: block;
    width: 16px;
    height: 1px;
    background-color: rgba(0,127,211,1);
    transform: translateX(-50%) rotate(-15deg);
}


  #header_bg {
    position: absolute;
  }


  .Footer {
    width: 100%;
    position: relative;
    display: flex;
    background-color: #002C5D;
    color: white;
  }

  .Footer_inner {
    width: 1600px;
    padding: 60px 80px;
    margin: auto;
  }

  .Footer_nav {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
  }

  .Footer_col {
    margin-right: 120px;
    position: relative;
		white-space: nowrap;
		text-align: left;
		font-style: normal;
		font-size: 16px;
    font-weight: bold;
		color: rgba(255,255,255,1);
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .Footer_contact {
    @apply mt-24 flex justify-between gap-4 border-t border-white pt-12;
  }

  .Footer_contact-mobile {
    display: none;
  }

  .Footer_contact-pc {
    display: flex;
    gap: 20px;
  }

  .Footer_contact-pages {
    @apply mt-16 flex justify-around gap-4;
  }

  @media screen and (max-width: 768px) {
    .Header {
      display: none;
    }

    .HeaderSp {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 72px;
      gap: 32px;
      padding: 0 20px;
      position: absolute;
      z-index: 100;
      width: 100%;
    }

    .HeaderSp_overlay.visible {
      display: block;
      opacity: 1;
    }

    .Header_lang {
      font-family: EB Garamond !important;
      display: flex;
      gap: 4px;
      align-items: center;
      justify-content: start;
      padding-left: 0px;
      font-family: serif;

      button.active {
        color: white;
      }
    }

    .Header_lang-acitve {
        position: absolute;
        bottom: 0px;
        left: 50%;
        z-index: 10;
        display: block;
        width: 16px;
        height: 1px;
        background-color: white;
        transform: translateX(-50%) rotate(-15deg);
    }

    .Footer_services {
        display: none;
    }

    .Footer_inner {
      width: 100%;
      padding: 60px 24px;
      margin: auto;
    }

    .Footer_contact {
      @apply mt-24 flex-col justify-between items-center gap-4 border-t border-white pt-12;
    }

    .Footer_contact-mobile {
      display: block;
    }

    .Footer_contact-pc {
      display: none;
    }

    .Footer_contact-pages {
      @apply mt-16 flex-col justify-around gap-4;
    }
  }
  


.reset-position {
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  transform: none !important;
}

.relative {
  position: relative !important;
}

.position-unset {
  position: unset !important;
}

.Path_278027_ftd {
  left: -200px;
  top: -72px;
}


.sub-header {
  position: relative;
  height: 240px;
  margin-top: 128px;
  display: flex;
  align-items: center;
  padding: 0 60px 12px 60px;
}

.sub-header-title {
  position: unset !important;
}

.sub-header-subtitle {
  position: unset !important;
}

.sub-header-bg {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 240px !important;
}

.container {
  position: relative;
  width: 100%;
  margin: auto;
}

#h2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Rectangle_24893_fti {
  transform: translate(-620px, -650px) matrix(1,0,0,1,717.9999,676.9073) rotate(-14deg) !important; 
  width: auto !important;
}

#RepeatGrid {
  display: grid;
  gap: 10px;
  grid-template-columns: 2fr 1fr;
}

main > div {
  position: relative !important;
  overflow: auto !important;
}

	#sub_footer {
		position: relative;
		width: 100%;
		// height: 600px;
    padding-block: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
		overflow: visible;
    background: rgba(0, 127, 211, 0.6);
    &::before {
      content: "";
      position: absolute;
      top: -50px;
      left: 0;
      background-color: white;
      width: 100%;
      height: 50px;
    }
	}

	#bg_tra_ {
		opacity: 0.601;
		fill: url(#bg_tra_);
	}
	#sub_footer .bg_tra_ {
		position: absolute;
		overflow: visible;
		width: 100%;
		height: auto !important;
		left: 0;
		top: 0px;
	}
	#slanting {
		fill: rgba(255,255,255,1);
	}
	#sub_footer .slanting {
		overflow: visible;
		position: absolute;
		width: 312px;
		height: 73px;
		transform: translate(0px, -4565.06px) matrix(1,0,0,1,-15.6875,4601.715) rotate(166deg);
		transform-origin: center;
		left: 0px;
		top: -72px;
	}
	#sub_footer #card_contact {
		position: relative;
		// width: auto !important;
    max-width: 500px;
		height: 390px;
		overflow: visible;
        top: auto;
    left: auto;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	}
	#Rectangle_24845 {
		opacity: 1;
		fill: rgba(255,255,255,1);
	}
	.Rectangle_24845 {
		position: absolute;
		overflow: visible;
		width: auto !important;
		height: 390px;
		left: 0px;
		top: 0px;
	}

	#Text {
		left: 40px;
		top: 184px;
		position: unset !important;
		overflow: visible;
		width: auto !important;
		line-height: 28px;
		margin-top: -6px;
		text-align: left;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(22,24,26,1);
	}
	#Text_be {
		left: 146px;
		top: 131px;
		position: unset !important;
		overflow: visible;
		width: auto !important;
		white-space: nowrap;
		line-height: 34px;
		margin-top: -7px;
		text-align: center;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,127,211,1);
		letter-spacing: 0.4px;
	}
  #Text_aksjd {
    left: 40px;
		top: 184px;
		position: absolute;
		overflow: visible;
		width: auto !important;
		line-height: 28px;
		margin-top: -6px;
		text-align: left;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(22,24,26,1);
  }
	#Group_30177 {
		position: unset !important;
		width: 84px !important;
		height: 70px !important;
		left: 208px !important;
		top: 40.001px !important;
		overflow: visible !important;
	}
	#Group_30177_bg {
		position: unset !important;
		width: 84px;
		height: 70px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#btn_contact {
		position: relative !important;
		// width: auto !important;
		height: 70px;
		left: 40px;
		top: 280px;
		overflow: visible;
    text-align: center;

    br {
      display: none;
      @media screen and (max-width: 400px) {
        display: inline;
      }
    }

    @media screen and (min-width: 767px) {
        > svg {
          transform: translateX(-10%);
          transition: transform .2s ease-in-out;
        }

        &:hover {
          > svg {
            transform: translateX(10%);
          }
        }
    }
	}
	#Rectangle_24479_bj {
		fill: url(#Rectangle_24479_bj);
	}
	.Rectangle_24479_bj {
		position: unset !important;
		overflow: visible !important;
		width: 100%;
		height: 70px;
		left: 0px;
		top: 0px;
	}
	#Text_bk {
		left: 154px;
		top: 21px;
		position: absolute;
		overflow: visible;
		width: 113px;
		white-space: nowrap;
		line-height: 30px;
		margin-top: -6px;
		text-align: center;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.4px;
	}
	#Path_277703 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Path_277703 {
		overflow: visible;
		position: absolute;
		width: 29px;
		height: 6.709px;
		left: auto !important;
    right: 16px;
		top: 30px;
		transform: matrix(1,0,0,1,0,0);
	}
	#sub_footer #card_dl {
		position: relative;
		width: auto !important;
		height: 390px;
		overflow: visible;
        top: auto;
    left: auto;
	}
	#Rectangle_24470 {
		opacity: 1;
		fill: rgba(255,255,255,1);
	}
	.Rectangle_24470 {
		position: absolute;
		overflow: visible;
		width: auto !important;
		height: 390px;
		left: 0px;
		top: 0px;
	}
	#PDF {
		left: 40px;
		top: 184px;
		position: unset !important;
		overflow: visible;
		width: 421px;
		height: 78px;
		line-height: 28px;
		margin-top: -6px;
		text-align: left;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(22,24,26,1);
	}
	#Text_bp {
		left: 146px;
		top: 131px;
		position: unset !important;
		overflow: visible;
		width: 209px;
		white-space: nowrap;
		line-height: 34px;
		margin-top: -7px;
		text-align: center;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,127,211,1);
		letter-spacing: 0.4px;
	}
	#Group_30178 {
		position: unset !important;
		width: 84px;
		height: 76px;
		left: 208px;
		top: 40px;
		overflow: visible;
	}
	#Group_30178_br {
		position: absolute;
		width: 45.683px;
		height: 72.375px;
		left: 19.316px;
		top: 1.934px;
		overflow: visible;
	}
	#Path_278129 {
		fill: rgba(0,0,0,0);
		stroke: rgba(0,127,211,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.Path_278129 {
		overflow: visible;
		position: absolute;
		width: 47.682px;
		height: 61.115px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_278130 {
		fill: rgba(0,0,0,0);
		stroke: rgba(0,127,211,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Path_278130 {
		overflow: visible;
		position: absolute;
		width: 15.631px;
		height: 14.775px;
		left: 31.051px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Line_138 {
		fill: transparent;
		stroke: rgba(0,127,211,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_138 {
		overflow: visible;
		position: absolute;
		width: 25.369px;
		height: 2px;
		left: 10.158px;
		top: 21.443px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Line_139 {
		fill: transparent;
		stroke: rgba(0,127,211,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_139 {
		overflow: visible;
		position: absolute;
		width: 25.369px;
		height: 2px;
		left: 10.158px;
		top: 29.482px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Line_140 {
		fill: transparent;
		stroke: rgba(0,127,211,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_140 {
		overflow: visible;
		position: absolute;
		width: 15.402px;
		height: 2px;
		left: 10.158px;
		top: 37.521px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_278131 {
		fill: rgba(0,0,0,0);
		stroke: rgba(0,127,211,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Path_278131 {
		overflow: visible;
		position: absolute;
		width: 29.135px;
		height: 28.001px;
		left: 9.293px;
		top: 46.456px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Rectangle_24664 {
		fill: rgba(0,0,0,0);
	}
	.Rectangle_24664 {
		position: absolute;
		overflow: visible;
		width: 84px;
		height: 76px;
		left: 0px;
		top: 0px;
	}
	#btn_contact_bz {
		position: relative !important;
		width: auto !important;
		height: 70px;
		left: auto !important;
		top: auto !important;
		overflow: visible;
	}
	#Rectangle_24479_ca {
		fill: url(#Rectangle_24479_ca);
	}
	.Rectangle_24479_ca {
		position: unset !important;
		overflow: visible;
		width: auto !important;
		height: 70px;
		left: 0px;
		top: 0px;
	}
	#Text_b {
		left: 89px;
		top: 21px;
		position: absolute;
		overflow: visible;
		width: 243px;
		white-space: nowrap;
		line-height: 30px;
		margin-top: -6px;
		text-align: center;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.4px;
	}
	#Path_277703_b {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Path_277703_b {
		overflow: visible;
		position: absolute;
		width: 29px;
		height: 6.709px;
		left: 356.516px;
		top: 30px;
		transform: matrix(1,0,0,1,0,0);
	}


form {
  width: 75%;
  max-width: 960px;
  margin: 0 auto 232px;

  p[class="error-message"] {
    margin-top: 4px;
    color: #f75f70;
    font-size: 14px;
  }

  > dl {
    > * + * {
      margin-top: 32px;
    }
    > div {
      display: flex;
      align-items: flex-start;
      > dt {
        flex: 1;

        > label, > span {
          position: relative;
          white-space: nowrap;
          font-size: 18px;
          color: #16181a;

          &::before {
            position: absolute;
            content: "";
            display: block;
            width: 8px;
            height: 8px;
            background-color: #007fd3;
            border-radius: 50%;
            right: -16px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
      > dd {
        flex-basis: 75%;

        > * + * {
          margin-top: 12px;
        }

        > div[class="radio-button"] {
          display: flex;
          > input[type="radio"] {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            position: relative;

            &::before {
              position: absolute;
              top: 50%;
              left: 10px;
              transform: translate(-50%, -50%);
              width: 20px;
              height: 20px;
              border-radius: 50%;
              background-color: #fff;
              outline: 1px solid #a0a0a0;
              content: '';
            }

            &:checked {
              &::after {
                position: absolute;
                top: 50%;
                left: 10px;
                transform: translate(-50%, -50%);
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: #000;
                content: '';
              }
            }
          }
          > label {
            margin-left: 32px;
            font-size: 16px;
          }
        }

        > div[class="file-selector"] {
          display: flex;
          align-items: center;

          > button[type="button"] {
            background-color: #f5f6f8;
            border: 1px solid #027fd3;
            color: #027fd3;
            font-size: 16px;
            padding: 16px 24px;
            border-radius: 3px;
          }
          > p {
            margin-left: 16px;
            font-size: 16px;
            color: #16181a;
          }
          > p[class="fileNameEmpty"] {
            color: #a0a0a0;
          }
          > input[type="file"] {
            display: none;
          }
        }

        > input[type="text"] {
          width: 100%;
          padding: 16px;
          border: 1px solid #a0a0a0;
          border-radius: 4px;
          font-size: 18px;

          &[data-is-error=true] {
            border: 2px solid #eb8e9f;
          }
        }
        > textarea {
          width: 100%;
          height: 16em;
          padding: 16px;
          border: 1px solid #a0a0a0;
          border-radius: 4px;
          font-size: 18px;
          line-height: 1.5;
          vertical-align: top;

          &[data-is-error=true] {
            border: 2px solid #eb8e9f;
          }
        }
      }
    }
  }

  .agree {
    width: 100%;
    text-align: center;
    margin: 88px 0 72px;

    .agree-note {
      font-size: 18px;

      a {
        color: rgba(0,127,211,1);
        text-decoration: underline;

        &:hover {
          color: rgba(0,127,211,.6);
        }
      }
    }
    .agree-input {
      input[type="checkbox"] {
        display: none;
      }
      label {
        position: relative;
        display: inline-block;
        margin-top: 32px;
        padding-left: 28px;
        cursor: pointer;
        font-size: 16px;

        // 枠線
        &::before {
          background: #fff;
          border: 1px solid #a0a0a0;
          border-radius: 2px;
          content: '';
          display: block;
          width: 20px;
          height: 20px;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate(-50%, -50%);
        }

        // チェックマーク
        &::after {
          border-right: 3px solid #027fd3;
          border-bottom: 3px solid #027fd3;
          content: '';
          display: block;
          width: 6px;
          height: 12px;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate(-50%, -50%) rotate(45deg);
          opacity: 0;
        }
      }
      input[type="checkbox"]:checked + label::after {
        opacity: 1;
      }
    }
  }
  .submit {
    width: 100%;
    text-align: center;

    input[type="submit"] {
      cursor: pointer;
      background: linear-gradient(to right, #007fd3, #279BE8) ;
      color: #fff;
      border-radius: 4px;
      font-weight: 600;
      padding: 24px 0;
      width: 300px;
      font-size: 18px;

      &:hover {
        filter: brightness(110%);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  form {
    width: 90%;
    max-width: 960px;
    margin: 0 auto 232px;

    p[class="error-message"] {
      margin-top: 8px;
      color: #f75f70;
      font-size: 24px;
    }

    > dl {
      > * + * {
        margin-top: 40px;
      }
      > div {
        display: flex;
        flex-direction: column;
        align-items: normal;

        > dt {
          flex: 1;
          margin-bottom: 18px;

          > label, > span {
            position: relative;
            white-space: nowrap;
            font-size: 32px;
            color: #16181a;

            &::before {
              position: absolute;
              content: "";
              display: block;
              width: 12px;
              height: 12px;
              background-color: #007fd3;
              border-radius: 50%;
              right: -20px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
        > dd {
          flex-basis: 100%;

          > * + * {
            margin-top: 24px;
          }

          > div[class="radio-button"] {
            display: flex;
            > input[type="radio"] {
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              position: relative;

              &::before {
                position: absolute;
                top: 50%;
                left: 10px;
                transform: translate(-50%, -50%);
                width: 32px;
                height: 32px;
                border-radius: 50%;
                background-color: #fff;
                outline: 1px solid #a0a0a0;
                content: '';
              }

              &:checked {
                &::after {
                  position: absolute;
                  top: 50%;
                  left: 10px;
                  transform: translate(-50%, -50%);
                  width: 12px;
                  height: 12px;
                  border-radius: 50%;
                  background-color: #000;
                  content: '';
                }
              }
            }
            > label {
              margin-left: 48px;
              font-size: 32px;
            }
          }

          > div[class="file-selector"] {
            display: flex;
            align-items: center;

            > button[type="button"] {
              background-color: #f5f6f8;
              border: 1px solid #027fd3;
              color: #027fd3;
              font-size: 28px;
              padding: 28px 36px;
              border-radius: 3px;
            }
            > p {
              margin-left: 28px;
              font-size: 28px;
              color: #16181a;
            }
            > p[class="fileNameEmpty"] {
              color: #a0a0a0;
            }
            > input[type="file"] {
              display: none;
            }
          }

          > input[type="text"] {
            width: 100%;
            padding: 28px;
            border: 1px solid #a0a0a0;
            border-radius: 4px;
            font-size: 32px;

            &[data-is-error=true] {
              border: 2px solid #eb8e9f;
            }
          }
          > textarea {
            width: 100%;
            height: 16em;
            padding: 16px;
            border: 1px solid #a0a0a0;
            border-radius: 4px;
            font-size: 32px;
            line-height: 1.5;
            vertical-align: top;

            &[data-is-error=true] {
              border: 2px solid #eb8e9f;
            }
          }
        }
      }
    }

    .agree {
      width: 100%;
      text-align: center;
      margin: 88px 0 72px;

      .agree-note {
        font-size: 28px;
      }
      .agree-input {
        input[type="checkbox"] {
          display: none;
        }
        label {
          position: relative;
          display: inline-block;
          margin-top: 32px;
          padding-left: 28px;
          cursor: pointer;
          font-size: 28px;

          // 枠線
          &::before {
            background: #fff;
            border: 1px solid #a0a0a0;
            border-radius: 2px;
            content: '';
            display: block;
            width: 40px;
            height: 40px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(-50%, -50%);
          }

          // チェックマーク
          &::after {
            border-right: 5px solid #027fd3;
            border-bottom: 5px solid #027fd3;
            content: '';
            display: block;
            width: 14px;
            height: 28px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(-50%, -50%) rotate(45deg);
            opacity: 0;
          }
        }
        input[type="checkbox"]:checked + label::after {
          opacity: 1;
        }
      }
    }
    .submit {
      width: 100%;
      text-align: center;

      input[type="submit"] {
        cursor: pointer;
        background: linear-gradient(to right, #007fd3, #279BE8) ;
        color: #fff;
        border-radius: 4px;
        font-weight: 600;
        padding: 40px 0;
        width: 560px;
        font-size: 32px;

        &:hover {
          filter: brightness(110%);
        }
      }
    }
  }
}


#img__fv_29324053_dqu {
  left: auto !important;
}

#img__fv_29324053, #img__fv_29324053_dqu {
  min-width: 125% !important;
  animation: slide 60s infinite;
}

#bg::after {
  content: "";
  width: 130vw;
  height: 130vh;
  position: fixed;
  top: -184px;
  left: 0;
  z-index: -1;
  background-image: url("/public/images/img__fv_29324053.png");
  background-repeat: no-repeat;
  background-size: cover;
  animation: slide 60s infinite;
  @media screen and (max-width: 1100px) {
    top: -21vw;
  }
  @media screen and (max-width: 1000px) {
    top: -26vw;
  }
  @media screen and (max-width: 900px) {
    top: -32vw;
  }
  @media screen and (max-width: 800px) {
    top: -40vw;
  }
}

@keyframes slide {
  0% {
    left: 0;
  }
  100% {
    left: -20%;
  }
}

#bg-sp::after {
  content: "";
  width: 140svw;
  height: 140svh;
  position: fixed;
  top: -20vh;
  left: 0;
  z-index: -1;
  background-image: url("/public/images/img__fv_29324053_dqu.png");
  background-repeat: no-repeat;
  background-size: cover;
  animation: slide-sp 60s infinite;
}

@keyframes slide-sp {
  0% {
    left: 0;
  }
  100% {
    left: -30%;
  }
}

.TopPage_cutout-line {
    z-index: 10;
    content: "";
    position: absolute;
    top: 64px;
    right: 0px;
    transform: rotate(-14deg);
    display: block;
    width: 600px;
    height: 5px;
    z-index: 10;

    &.top {
      top: 163px;
      left: 154px;
    }

    &.left {
      span {
        transform-origin: left;  /* 左側を固定点に設定 */
      }
    }

    &.right {
      span {
        transform-origin: right;  /* 左側を固定点に設定 */
      }
    }

    span {
      display: block;
      height: 100%;
      width: 100%;
      background-color: #AFDFFD;
      transform: scaleX(0);  /* 初期状態で幅を0に設定 */
      animation: line-show 1.5s ease-out forwards;  /* アニメーション設定 */
    }
}

.TopPage_cutout-line-2 {
    transform: translate(-50%, -50%) rotate(-14deg);
    position: absolute;
    top: 132px;
    right: 0px;

    &::after {
      content: "";
      position: absolute;
      text-align: center;
      top: 0px;
      right: 50%;
      display: block;
      width: 600px;
      height: 5px;
      background-color: #AFDFFD;
      z-index: 10;
      animation: expandWidth 1s ease-out;
    }

    &.top {
      top: 163px;
      left: 154px;
    }
}

.TopPage_cutout-line-3 {
    content: "";
    position: absolute;
    top: 28px;
    left: -8px;
    z-index: 10;
    transform: rotate(-14deg);
    display: block;
    width: 600px;
    height: 5px;
    background-color: #AFDFFD;
    animation: expandWidth 2s ease-out forwards; /* 2秒間で実行、終了時に最後の状態を保持 */
}


.TopPage_cutout-line-4 {
    z-index: 10;
    content: "";
    position: absolute;
    top: -25px;
    right: -12px;
    transform: rotate(-14deg);
    display: block;
    width: 400px;
    height: 5px;
    background-color: #AFDFFD;
    z-index: 10;

    &.top {
      top: 163px;
      left: 154px;
    }
}

@keyframes expandWidth {
  0% {
    width: 0;
  }
  100% {
    width: 600px;
  }
}

.PageTitle_cutout-line {
    content: "";
    position: absolute;
    top: 90px;
    left: 420px;
    transform: translate(-50%, -50%) rotate(-14deg);
    display: block;
    width: 460px;
    height: 5px;
    background-color: #AFDFFD;

    &.top {
      top: 163px;
      left: 154px;
    }
}

.PageTitle_cutout-line-sp {
      content: "";
    position: absolute;
    top: 77px;
    left: 174px;
    transform: translate(-50%, -50%) rotate(-14deg);
    display: block;
    width: 460px;
    height: 5px;
    background-color: #AFDFFD;

    &.top {
      top: 163px;
      left: 154px;
    }
}

.Footer_cutout-line {
    content: "";
    position: absolute;
    top: 20px;
    left: 212px;
    transform: translate(-50%, -50%) rotate(-14deg);
    display: block;
    width: 560px;
    height: 5px;
    background-color: #AFDFFD;

    &.top {
      top: 163px;
      left: 154px;
    }
}

.Footer_cutout-line-2 {
    content: "";
    position: absolute;
    top: 40px;
    left: 40px;
    transform: translate(-50%, -50%) rotate(-14deg);
    display: block;
    width: 460px;
    height: 5px;
    background-color: #AFDFFD;

    &.top {
      top: 163px;
      left: 154px;
    }
}

  @media screen and (max-width: 768px) {
    #sub_footer {
      flex-direction: column;
      padding: 60px 24px;
		  height: auto;
      // margin-top: -680px;
    }

    #sub_footer #card_contact {
      position: relative;
      width: 100%;
      height: 390px;
      overflow: visible;
          top: auto;
      left: auto;
    }

    #sub_footer .slanting {
      overflow: visible;
      position: absolute;
      width: 312px;
      height: 73px;
      transform: translate(0px, -4565.06px) matrix(1,0,0,1,-15.6875,4601.715) rotate(166deg);
      transform-origin: center;
      left: -160px;
      top: -72px;
    }

    .Footer_cutout-line {
        content: "";
        position: absolute;
        top: 20px;
        left: 54px;
        transform: translate(-50%, -50%) rotate(-14deg);
        display: block;
        width: 360px;
        height: 3px;
        background-color: #AFDFFD;

        &.top {
          top: 163px;
          left: 154px;
        }
    }

    .Footer_cutout-line-2 {
        content: "";
        position: absolute;
        top: 50px;
        left: -120px;
        transform: translate(-50%, -50%) rotate(-14deg);
        display: block;
        width: 460px;
        height: 3px;
        background-color: #AFDFFD;

        &.top {
          top: 163px;
          left: 154px;
        }
    }

  }


.Header_menu-acitve {
    position: absolute;
    bottom: -8px;
    left: 50%;
    z-index: 10;
    display: block;
    width: 32px;
    height: 2px;
    background-color: rgba(0,127,211,1);
    transform: translateX(-50%) rotate(-15deg);
}


.ServiceList {
  position: relative;
  margin: auto;
  z-index: 10;
  max-width: 1080px;
  padding: 24px 24px;
  display: flex;
  justify-content: start; /* Adjust space between items */
  flex-wrap: wrap; /* Allows items to wrap if the container is not wide enough */
  gap: 24px; 

  .ServiceList_item {
    height: 65px;
    width: 320px; /* Fixed width; consider using flex-basis and max-width for responsiveness */
    // flex: 1 1 calc(33.333% - 48px); /* 3つのアイテムがコンテナ幅を均等に分け合う */
    // flex-grow: 1; /* Allows items to grow if necessary, consider removing if keeping fixed widths */
    // flex-basis: calc(33.333% - 48px);
    background-color: white;
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    color: rgba(0,127,211,1);
    transition: all 0.2s ease-in-out;

    &:hover {
      opacity: 0.6;
    }

    .ServiceList_item-icon-white{
      display: none;
    }

    .ServiceList_item-icon {
      display: block;
    }

    .ServiceList_item-icon, .ServiceList_item-icon-white {
      width: 26px;
      height: 206px;
    }

    span {
      width: 80%;
    }

    &.active {
      background: linear-gradient(90deg, #007ed3 0%, #289be8 100%);
      color: white;
      .ServiceList_item-icon-white {
        display: block;
      }
      .ServiceList_item-icon {
        display: none;
      }

      &:hover {
        background: linear-gradient(90deg, #0e89da 0%, #7ccafd 100%);
        opacity: 1;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .ServiceList {
    gap: 40px;
    justify-content: space-between;

    .ServiceList_item {
      width: calc(50% - 24px);
      padding: 24px 40px;
      font-size: 22px;
      height: 120px;
      border-radius: 60px;

      .ServiceList_item-icon, .ServiceList_item-icon-white {
        width: 40px;
        height: 40px;
      }
    }
  }
}

.ServiceContent {
  display: flex;
  justify-content: space-between; /* Adjust space between items */
  flex-wrap: wrap; /* Allows items to wrap if the container is not wide enough */
  gap: 12px;
  width: 100%;

  .ServiceContent_item {
    background-color: #ffffff;
    border-radius: 3px;
    width: 48%;
    padding: 24px;
    text-align: center;
    position: relative;
    font-size: 18px;

    &::after {
      content: "";
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background: rgba(0,127,211,1);
      position: absolute;
      top: calc(50% - 5px);
      left: 32px;
    }
  }
}

#Group_30801 {
  width: auto !important;
}

#content > div {
  overflow: hidden !important;
}

.TopService {
  .service-icon-white {
    display: none;
    transform: translateY(0%);
  }
  .service-icon {
    display: block;
  }
}

.TopService:hover {
  background-color: #007fd3;
  * {
    color: #ffffff;
  }

  .service-icon-white {
    display: block;
    animation: slideUp 0.4s ease-in-out;
    transform: translateY(-10%);
  }
  .service-icon {
    display: none;
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-10%);
  }
}
@keyframes gradientHoverAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.gradient-hover {
  position: relative;
  overflow: hidden;
  background: linear-gradient(90deg, #007ed3 0%, #289be8 100%);
  border-radius: 4px;
  cursor: pointer;
}

.btn-arrow {
  transition: transform 0.2s ease-in-out;
}

.gradient-hover:hover {
  // #0e89da to #7ccafd 
  background: linear-gradient(90deg, #0e89da 0%, #7ccafd 100%);

  .btn-arrow {
    transform: translateX(20%);
  }
}

@media screen and (max-width: 768px) {
  form > dl > div > dd > div[class=radio-button] {
    align-items: center !important;
    margin-left: 8px !important;
  }

  .ServiceContent {
    .ServiceContent_item {
      width: 100%;
      padding: 36px;
      font-size: 28px;

      &::after {
        content: "";
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background: rgba(0,127,211,1);
        position: absolute;
        top: calc(50% - 5px);
        left: 32px;
      }
    }
  }

}

#breadcrumb_ws > div:nth-child(2) {
  top: 20px !important;
}

#breadcrumb > div:nth-child(2) {
  top: 35px;
}

.card_merit > div:nth-child(2), .card_merit_sp > div:nth-child(2) {
  top: -16px !important; 
}

#service_sp {
  width: 100% !important;
}

.line {
    position: absolute;
    top: 64px;  /* 例としての位置指定 */
    right: 0px;
    width: 600px;  /* 最終的な幅 */
    height: 5px;
    overflow: hidden;  /* spanがはみ出さないように設定 */
}

.line span {
    display: block;
    height: 100%;
    width: 100%;
    background-color: #AFDFFD;
    transform: scaleX(0);  /* 初期状態で幅を0に設定 */
    transform-origin: left;  /* 左側を固定点に設定 */
    animation: line-show 2s ease-out forwards;  /* アニメーション設定 */
}

@keyframes line-show {
    from {
        transform: scaleX(0);  /* 開始時の幅 */
    }
    to {
        transform: scaleX(1);  /* 終了時に全幅 */
    }
}

.Blog span {
  transition: all .3s ease;
  cursor: pointer;
  max-height: 4rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

  @media screen and (max-width: 768px) {
    max-height: 6rem;
  }
}

.Blog span:hover {
  color: #007FD3;
}

.googleMapPinIcon {
  transition: .1s ease-in-out;
}

.btn_primary:hover {
  .googleMapPinIcon {
    transform: translateY(-4px);
  }
}

#shutterstock_323840189_cf {
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    width: 242px;
    height: 62px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(245,246,248,1);
    clip-path: polygon(0 0, 100% 0, 0 100%);
  }
}

.blogThumbnailImage {
  transition: all 0.3s ease;
  object-fit: cover;

  &:hover {
    transform: scale(1.1);
  }
}

// /hiring/thanks ページの「トップページへ戻る」ボタン
#btn_return_euw {
  * {
    cursor: pointer;
  }

  #Text_euy {
    transition: all .2s ease-in-out;
    opacity: 1;
  }
  .Path_277703_euz {
    transition: all .2s ease-in-out;
    transform: translateX(0);
    opacity: 1;
  }

  &:hover {
    #Text_euy {
      opacity: .7;
    }
    .Path_277703_euz {
      opacity: .7;
      transform: translateX(-10px);
    }
  }
}

// /contact/thanks ページの「トップページへ戻る」ボタン
#btn_return {
  * {
    cursor: pointer;
  }

  #Text_bqh {
    transition: all .2s ease-in-out;
    opacity: 1;
  }
  .Path_277703_bqi {
    transition: all .2s ease-in-out;
    transform: translateX(0);
    opacity: 1;
  }

  &:hover {
    #Text_bqh {
      opacity: .7;
    }
    .Path_277703_bqi {
      opacity: .7;
      transform: translateX(-10px);
    }
  }
}

// /contact/materials/thanks ページ（PC）の「トップページへ戻る」ボタン
#btn_return_eb {
  * {
    cursor: pointer;
  }

  #Text_fau {
    transition: all .2s ease-in-out;
    opacity: 1;
  }
  .Path_277703_ee {
    transition: all .2s ease-in-out;
    transform: translateX(0);
    opacity: 1;
  }

  &:hover {
    #Text_fau {
      opacity: .7;
    }
    .Path_277703_ee {
      opacity: .7;
      transform: translateX(-10px);
    }
  }
}

// /contact/materials/thanks ページ（PC）の「資料をダウンロード」ボタン
#btn_primary_es {
  background: linear-gradient(90deg, #007ed3 0%, #289be8 100%);
  border-radius: 4px;
  cursor: pointer;

  * {
    cursor: pointer;
  }

  #Text_fa {
    top: 24px;
  }

  #Path_278127_ey {
    transition: all .2s ease-in-out;
  }

  &:hover {
    background: linear-gradient(90deg, #0e89da 0%, #7ccafd 100%);
    #Path_278127_ey {
      transform: translateY(4px);
    }
  }
}
